<template>
  <div class="row">
    <b-modal ref="amenitiesmodal" no-enforce-focus hide-header>
      <form @submit.prevent="saveAmenities">
        <b-form-group label="Amenities included" label-for="amenities">
          <blueprint-search-input
            id="amenities"
            v-model="selectedItem.amenities"
            placeholder="Type to search"
            api-route-path="inventory/amenities"
            :state="groupsState('amenities')"
            :multiple="true"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="saveAmenities()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <div class="col">
      <b-card title="Unit types">
        <blueprint-data api-route-path="inventory/types" :fields="fields">
          <template v-slot:unitcount="{ item }">
            {{ item.unitCount }}
          </template>
          <template v-slot:actions="{ item }">
            <AccessRulesLink :type-id="item.id" />
            <a href="#" @click.prevent="amenities(item)"><fa-icon icon="couch" />Amenities</a>
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <h4>Local Access Control</h4>
            <b-form-checkbox
              v-model="item.accessLocalUsers"
              :value="true"
              :unchecked-value="false"
            >
              Local pin code users
            </b-form-checkbox>
            <b-form-checkbox
              id="accessLocalCards"
              v-model="item.accessLocalCards"
              :value="true"
              :unchecked-value="false"
            >
              Access cards
            </b-form-checkbox>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faShoppingBasket, faCouch,
} from '@fortawesome/free-solid-svg-icons';
import AccessRulesLink from '../../../service-modules/larva_devices/components/type-access-rules-link.vue';

library.add(faShoppingBasket, faCouch);

export default {
  components: {
    AccessRulesLink,
  },
  data () {
    return {
      selectedItem: {},
      typeId: '',
      errors: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'unitcount',
          label: 'Unit count',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  methods: {
    amenities (item) {
      const { id } = item;
      this.selectedItem = {};
      this.typeId = id;
      this.$http.get(`inventory/types/${id}/amenities`)
        .then((res) => {
          this.selectedItem.amenities = res.data;
          this.errors = {};
          this.$refs.amenitiesmodal.show();
        })
        .catch(this.err);
    },
    saveAmenities () {
      this.$http.patch(`inventory/types/${this.typeId}/amenities`, this.selectedItem.amenities || [])
        .then(() => {
          this.$refs.amenitiesmodal.hide();
        })
        .catch(this.err);
    },
    groupsState (item) {
      return (this.errors[item] ? false : null);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  },
};
</script>
