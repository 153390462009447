<template>
  <span>
    <a v-if="modules && !!modules['larva_devices']" href="#" @click.prevent="getAccessrules(typeId)"><fa-icon icon="user-shield" />Access rules</a>
    <b-modal ref="accessrulesmodal" size="lg" no-enforce-focus hide-header>
      <form @submit.prevent="saveAccessRules">
        <b-button-toolbar class="p-b-2 float-right" key-nav>
          <b-button-group>
            <b-button variant="success" @click="accessrules.push({ type: 'group'});">
              <fa-icon icon="plus" /> New
            </b-button>
          </b-button-group>
        </b-button-toolbar>

        <b-table
          empty-html="No items to show" :fields="accessRulesFields" :items="accessrules" small striped hover
          show-empty
        >
          <template v-slot:cell(type)="{ item }">
            <span v-if="item.default === true">
              * Default rule
            </span>
            <b-form-select v-else v-model="item.type">
              <option value="role">Users with role</option>
              <!-- --->
              <option v-if="modules && !!modules['services']" value="service">Users who have subscribed to service</option>
              <option value="group">Users and cards listed under group</option>
            </b-form-select>
            <span v-if="item.type === 'service'">
              NB! Users are automatically subscribed to unit included services with active booking.
            </span>
          </template>
          <template v-slot:cell(param)="{ item }">
            <span v-if="item.default === true">
              All users and cards listed under valid booking
            </span>
            <b-form-select v-if="item.type==='role'" v-model="item.role">
              <option value="ADMIN">Admin</option>
              <option value="MANAGER">Manager</option>
              <option value="BILLINGMANAGER">Biling Manger</option>
              <option value="AUDITOR">Auditor</option>
            <!-- <option value="USER">User role</option> --->
            </b-form-select>
            <blueprint-search-input
              v-if="item.type==='service'"
              id="services"
              v-model="item.serviceId"
              placeholder="Type to search"
              api-route-path="inventory/services"
              :state="state('services')"
            />
            <blueprint-search-input
              v-if="item.type==='group'"
              id="groups"
              v-model="item.groupId"
              placeholder="Type to search"
              api-route-path="groups"
              :state="state('groups')"
            />
          </template>
          <template v-slot:cell(actions)="{ item }">
            <a v-if="!item.default" href="#" class="text-danger" title="Delete" @click.prevent="removeAccessRule(item)">
              <fa-icon icon="trash" />
            </a>
          </template>
        </b-table>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="saveAccessRules()">Save</b-button>
        <b-button variant="dark" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
/* eslint-disable no-param-reassign */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserShield, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

library.add(faUserShield, faPlus, faTrash);

export default {
  props: ['typeId'],
  data () {
    return {
      accessrules: [],
      accessRulesFields: [
        {
          key: 'type',
          label: 'Rule type',
          sortable: false,
          slot: true,
        },
        {
          key: 'param',
          label: 'Rule param',
          sortable: false,
          slot: true,
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    removeAccessRule (item) {
      const index = (this.accessrules).indexOf(item);
      if (index > -1) {
        this.accessrules.splice(index, 1);
      }
    },
    getAccessrules (typeId) {
      this.$http.get(`inventory/types/${typeId}/accessrules`)
        .then((res) => {
          this.accessrules = _.map(res.data || [], (rule) => {
            if (rule.role) {
              rule.type = 'role';
            } else if (rule.groupId) {
              rule.type = 'group';
            } else if (rule.serviceId) {
              rule.type = 'service';
            }
            return rule;
          });
          this.accessrules.unshift({
            default: true,
          });
          this.errors = {};
          this.$refs.accessrulesmodal.show();
        })
        .catch(this.err);
    },
    saveAccessRules () {
      const rules = _.chain(this.accessrules)
        .cloneDeep()
        .filter(rule => !rule.default && (rule.type === 'role' || rule.type === 'group' || rule.type === 'service'))
        .map((rule) => {
          if (rule.type === 'role') {
            return { role: rule.role };
          }
          if (rule.type === 'group') {
            return { groupId: rule.groupId };
          }
          return { serviceId: rule.serviceId };
        });
      this.$http.patch(`inventory/types/${this.typeId}/accessrules`, rules)
        .then(() => {
          this.$refs.accessrulesmodal.hide();
        })
        .catch(this.err);
    },

  },
};
</script>
